import { CartPassengerInfo } from './cartpassengerinfo.model';
import { Attribute } from './attribute.model';

export interface CartItem {
    //both
    productId: string;
    catalogId: string;
    associatedPassenger: CartPassengerInfo;
    attributes?: Attribute | Attribute[];

    //1S
    quantity?: number;
    assignment?: string;
    price?: number;
    seatCategory?: string;
    type?: string;

    //1A
    offerId?: string;
    seat?: string;
    description?: string;
    pricedCabin?: string;
    serviceDefinitionId?: string;
    priceDifference?: number;
}

// INCOMPLETE LIST
// Replace description typing in CartItem when complete
export enum CartItemDescription {
  MAIN_CABIN_SEAT = 'Standard',
  PREFERRED_SEAT = 'Preferred Seat',
  EXTRA_COMFORT = 'Extra Comfort Seat',
  FIRST_CLASS = 'First',
  REGULAR_BAG = 'RegularBag',
}
