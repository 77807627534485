import { CATALOG_ID_SEAT, CATALOG_ID_BAG, CATALOG_ID_SEAT_AMADEUS } from '~app/store/services/cart/cart.service';
import { Action, createReducer, on } from '@ngrx/store';
import { Response } from '../../../models/response.model';
import { Cart } from '~app/models/cart.model';
import * as CartActions from '../../actions/cart.actions';
import * as OrderFulfillActions from '../../actions/orderFulfill.actions'
import { CartItem } from '~app/models/cartitem.model';

export interface ICartState {
  cart: Response<Cart>;
  loading: boolean;
  error: boolean;
  cartResultItems?: CartItem[];
  cartItems?: CartItem[];
  storedItems?: CartItem[];
  bags?: CartItem[];
  seats?: CartItem[];
  cartItemsUpdateSuccess?: boolean;
  grandTotal?: number;
  seatsTotalCost?: number;
  bagsTotalCost?: number;
  correlationId?: string;
  checkout: boolean;
  id?: string;
}

export const initialCartitemState: ICartState = {
  cart: null,
  loading: false,
  error: false,
  cartResultItems: [],
  cartItems: [],
  storedItems: [],
  bags: [],
  seats: [],
  cartItemsUpdateSuccess: false,
  grandTotal: 0,
  seatsTotalCost: 0,
  bagsTotalCost: 0,
  correlationId: '',
  checkout: false,
  id: ""
};

export const cartReducer = createReducer(
  initialCartitemState,
  // create cart
  on(CartActions.createCartError, (state) => ({ ...state, loading: false, error: true })),
  on(CartActions.createCartErrCheckinInProgress, (state) => ({ ...state, loading: false, error: true })),
  on(CartActions.createCartErrPaymentInProgress, (state) => ({ ...state, loading: false, error: true })),
  on(CartActions.createCartErrSessionEnded, (state) => ({ ...state, loading: false, error: true })),

  // create cart on passenger route module
  on(CartActions.createCartPassengers, state => ({ ...state, loading: false, checkout: false })),
  on(CartActions.getCartSuccessPassengers, (state, { cart }) => ({
    ...state,
    loading: false,
    cart,
    id: cart.results[0].id
  })),
  on(CartActions.recreateCartPassengers, state => ({ ...state, cart: null, loading: false, error: false, checkout: false })),
  on(CartActions.recreateCartSuccessPassengers, (state, { cart }) => ({
    ...state,
    loading: false,
    cart,
    bags: [],
    seats: [],
    storedItems: [],
    id: cart.results[0].id,
    grandTotal: cart.results[0].grandTotal,
    cartResultItems: cart.results[0].items,
    cartItems: cart.results[0].items,
    seatsTotalCost: 0,
    bagsTotalCost: 0,
  })),
  on(CartActions.refreshCartAfterCreate, _ => ({
    ...initialCartitemState
  })),

  // create cart on dashboard route module
  on(CartActions.createCartDashboard, state => ({ ...state, loading: true, checkout: false })),
  on(CartActions.getCartSuccessDashboard, (state, { cart }) => ({
    ...state,
    loading: false,
    cart,
    id: cart.results[0].id
  })),
  on(CartActions.recreateCartDashboard, state => ({ ...state, cart: null, loading: true, error: false, checkout: false })),
  on(CartActions.recreateCartSuccessDashboard, (state, { cart }) => ({
    ...state,
    loading: false,
    cart,
    bags: [],
    seats: [],
    storedItems: [],
    id: cart.results[0].id,
    grandTotal: cart.results[0].grandTotal,
    cartResultItems: cart.results[0].items,
    cartItems: cart.results[0].items,
    seatsTotalCost: 0,
    bagsTotalCost: 0,
  })),

  // get cart
  on(CartActions.getCartSeatmapModal, state => ({ ...state, cart: null, loading: true, error: false, checkout: false })),
  on(CartActions.getCartSuccess, (state, { cart }) => ({ ...state, loading: false, cart })),
  on(CartActions.getCartError, (state) => ({ ...state, loading: false, error: true })),
  on(CartActions.getCartWithBags, (state) => ({ ...state, loading: true })),
  // delete cart
  on(CartActions.deleteCart, state => ({ ...state, cart: null, loading: true, error: false, checkout: false })),
  on(CartActions.deleteCartSuccess, (state) => ({ ...initialCartitemState, loading: false, error: false })),
  on(CartActions.deleteCartError, (state) => ({ ...state, loading: false, error: true })),
  // update cart with items
  on(CartActions.updateCartSuccess, (state, { cart }) => ({
    ...state,
    cart,
    cartResultItems: cart.results[0].items,
    cartItems: cart.results[0].items,
    loading: false,
    bags: cart.results[0].items.filter(item => item.catalogId === CATALOG_ID_BAG),
    seats: cart.results[0].items.filter(item => item.catalogId === CATALOG_ID_SEAT || item.catalogId ===  CATALOG_ID_SEAT_AMADEUS),
    cartItemsUpdateSuccess: true,
    grandTotal: cart.results[0].grandTotal,
    seatsTotalCost: cart.results[0].totalPrices.find(item => item.catalogId === CATALOG_ID_SEAT || item.catalogId ===  CATALOG_ID_SEAT_AMADEUS) ?
      cart.results[0].totalPrices.find(item => item.catalogId === CATALOG_ID_SEAT || item.catalogId ===  CATALOG_ID_SEAT_AMADEUS).price : 0,
    bagsTotalCost: cart.results[0].totalPrices.find(item => item.catalogId === CATALOG_ID_BAG) ?
      cart.results[0].totalPrices.find(item => item.catalogId === CATALOG_ID_BAG).price : 0,
    correlationId: cart.correlationId
  })),
  on(CartActions.updateCartError, (state) => ({ ...state, loading: false, error: true })),
  on(CartActions.updateCartWithItems('[Bags Form Component] Update Cart With Items'), (state, action) =>
    updateCartWithItems(state, action)
  ),
  on(CartActions.updateCartWithItems('[Seatmap Component] Update Cart With Items'), (state, action) =>
    updateCartWithItems(state, action)
  ),
  on(CartActions.updateCartWithItemsSuccess, (state, { cart }) => ({
    ...state,
    cart,
    cartResultItems: cart.results[0].items,
    cartItems: cart.results[0].items,
    loading: false,
    bags: cart.results[0].items.filter(item => item.catalogId === CATALOG_ID_BAG),
    seats: updateSeatItems(state.seats, cart.results[0].items.filter(item => item.catalogId === CATALOG_ID_SEAT || item.catalogId ===  CATALOG_ID_SEAT_AMADEUS)),
    cartItemsUpdateSuccess: true,
    grandTotal: cart.results[0].grandTotal,
    seatsTotalCost: cart.results[0].totalPrices.find(item => item.catalogId === CATALOG_ID_SEAT || item.catalogId ===  CATALOG_ID_SEAT_AMADEUS) ?
      cart.results[0].totalPrices.find(item => item.catalogId === CATALOG_ID_SEAT || item.catalogId ===  CATALOG_ID_SEAT_AMADEUS).price : 0,
    bagsTotalCost: cart.results[0].totalPrices.find(item => item.catalogId === CATALOG_ID_BAG) ?
      cart.results[0].totalPrices.find(item => item.catalogId === CATALOG_ID_BAG).price : 0,
    correlationId: cart.correlationId
  })),
  on(CartActions.cartApiError('[Bags Form Component] Update Cart With Items Error'),
  (state) => ({ ...state, loading: false, error: true })),
  on(CartActions.cartApiError('[Seatmap Component] Update Cart With Items Error'), (state) => ({
    ...state, loading: false, error: true
  })),
  on(CartActions.updateCartWithItemsError, (state) => ({ ...state, loading: false, error: true })),
  on(CartActions.updateCartByRemovingASeatItem, (state, action) => {
    const itemToRemoveIndex = state.seats.findIndex(seat => seat.associatedPassenger.segmentId === action.segmentId
      && seat.associatedPassenger.passengerId === action.passengerId && seat.associatedPassenger.flightId === action.flightId);
    const filteredSeats = state.seats.filter((_, index) => index !== itemToRemoveIndex);
    return {
      ...state,
      loading: true,
      seats: filteredSeats,
      cartItems: (!!state.bags.length) ? [...state.bags, ...filteredSeats] : filteredSeats
    };
  }),

  // >>> checkout
  on(CartActions.checkoutRequestProcessed,
    state => ({ ...state, loading: false })),
  on(CartActions.checkoutRequestFromPaymentRoute,
    state => ({ ...state, loading: true, error: false })),
  on(CartActions.checkoutCartFromPaymentRoute, state => ({ ...state, loading: true, error: false })),
  on(CartActions.checkoutCartFromPaymentRouteSuccess, _ => ({
    cart: null,
    loading: false,
    error: false,
    cartResultItems: [],
    cartItems: [],
    bags: [],
    seats: [],
    cartItemsUpdateSuccess: false,
    seatsTotalCost: 0,
    bagsTotalCost: 0,
    grandTotal: 0,
    correlationId: '',
    checkout: true,
    id: ""
  })),
  on(CartActions.checkoutCartFromPaymentRouteFailure, (state) => ({ ...state, cart: null, loading: false, error: true })),
  // <<< checkout
  on(CartActions.cartErrorAccepted, state => ({
    ...state,
    loading: false,
    error: false,
    checkout: false,
  })),
  on(CartActions.storeCartItems, state => ({
    ...state,
    storedItems: state.cartItems
  })),

  //===== ORDERFULFILL REDUCERS ======
  on(OrderFulfillActions.orderFulfill, state => ({ ...state, loading: true, error: false })),
  on(OrderFulfillActions.orderFulfillFailure, (state) => ({ ...state, cart: null, loading: false, error: true })),
  on(OrderFulfillActions.getOrderStatusError, (state) => ({ ...state, cart: null, loading: false, error: true })),
  on(OrderFulfillActions.getOrderStatusSuccess, _ => ({
    cart: null,
    loading: false,
    error: false,
    cartResultItems: [],
    cartItems: [],
    bags: [],
    seats: [],
    cartItemsUpdateSuccess: false,
    seatsTotalCost: 0,
    bagsTotalCost: 0,
    grandTotal: 0,
    correlationId: '',
    checkout: true,
    id: ""
  }))
);
interface UpdateCartWithItems {
  itemType: string;
  catalogId: string;
  cartItem: Partial<CartItem[]>;
  cart?: Response<Cart>;
}
function updateCartWithItems(cartState: ICartState, action: UpdateCartWithItems): ICartState {
  let actionCartItem = [...action.cartItem];
  let modifiedCartItem = (!!cartState[action.itemType].length && action.cartItem.length === 1)
    ? cartState[action.itemType].map(item => {
      if (!!actionCartItem.length && item.catalogId === action.catalogId
        && (actionCartItem[0].associatedPassenger?.journeyId
          ? item.associatedPassenger.journeyId === actionCartItem[0].associatedPassenger.journeyId
          && item.associatedPassenger.passengerId === actionCartItem[0].associatedPassenger.passengerId
          && item.associatedPassenger.segmentIds?.toString() === actionCartItem[0].associatedPassenger.segmentIds?.toString()
          && item.associatedPassenger.passengerSegmentIds?.toString() === actionCartItem[0].associatedPassenger.passengerSegmentIds?.toString()
          && (action.itemType === 'seats' ? item.associatedPassenger?.flightId === actionCartItem[0].associatedPassenger?.flightId : true)
          : item.associatedPassenger.segmentId === actionCartItem[0].associatedPassenger.segmentId
          && item.associatedPassenger.flightId === actionCartItem[0].associatedPassenger.flightId
          && item.associatedPassenger.passengerId === actionCartItem[0].associatedPassenger.passengerId) 
        && (item.productId === actionCartItem[0].productId || item.catalogId === CATALOG_ID_SEAT || item.catalogId === CATALOG_ID_SEAT_AMADEUS)
        ) {
        const modItem = { ...item, ...actionCartItem[0] };
        actionCartItem = [];
        return modItem;
      } else {
        return item;
      }
    })
    : actionCartItem;

  modifiedCartItem = modifiedCartItem.filter(item => item.quantity > 0);

  modifiedCartItem = (!!actionCartItem.length && actionCartItem.length === 1)
    ? [...cartState[action.itemType], ...actionCartItem] : modifiedCartItem;

  const otherCartType = (action.itemType === 'seats') ? 'bags' : 'seats';

  return {
    ...cartState,
    cart: action.cart,
    loading: true,
    [action.itemType]: modifiedCartItem,
    cartItems: [...cartState[otherCartType], ...modifiedCartItem],
    cartItemsUpdateSuccess: false
  };
}

function updateSeatItems(stateSeatItems: CartItem[], currentSeatItems: CartItem[]): CartItem[] {
    return stateSeatItems.map(seat => {
    const duplicate = currentSeatItems.find(current => current.associatedPassenger.passengerId === seat.associatedPassenger.passengerId &&
                                        current.associatedPassenger.segmentId === seat.associatedPassenger.segmentId &&
                                        current.associatedPassenger.flightId === seat.associatedPassenger.flightId);
    if (!!duplicate) {
          return {
              ...seat,
              ...duplicate
          };
      } else {
          return seat;
      }
    });
  }

export function reducer(state: ICartState | undefined, action: Action) {
  return cartReducer(state, action);
}
