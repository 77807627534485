import { Router } from '@angular/router';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as RouterActions from '~app/store/actions/router.actions';
import * as SessionActions from '~app/store/actions/session.actions';
import * as AuthTokenActions from '~app/store/actions/auth-token.actions';
import * as CartActions from '~app/store/actions/cart.actions';
import * as TripActions from '../../actions/trip.actions';
import * as SecurityInfoActions from '~app/store/actions/security-info.actions';
import * as InternationalDetailsActions from '~app/store/actions/international-details.actions';
import { concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { actionPrecedenceList, CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';
import { of } from 'rxjs';
import { Action, select, Store } from '@ngrx/store';
import * as fromSession from '../../services/session/session.selectors';
import * as fromSecurityInfo from '../../services/security-info/security-info.selectors';
import * as fromCart from '../../services/cart/cart.selectors';
import * as fromRouter from '../../services/router/router.selectors';
import * as fromTrip from '../../services/trip/trip.selectors';
import { EntityState } from '~app/store';
import { ModalsService } from '~app/modals/modals.service';
import { Terms } from '~app/models/terms.model';
import { routerNavigationAction } from '@ngrx/router-store';
import { Constants, MilitaryTravelType } from '~app/constants/ha-constants';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class RouterEffects {
  routeNavigate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationAction),
      switchMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromRouter.getRouterState)), this.store.pipe(select(fromSession.getSessionState))),
        concatMap(([_, routeState, sessionState]) => {
          let actions = [RouterActions.routeToSuccess(`[${routeState.params.serverRoute}]`)()];
          if (routeState.params.serverRoute === "/" && sessionState.authToken) {
            if (isPlatformBrowser(this.platformId)) {
              actions = [
                SessionActions.resetHeader(),
                TripActions.clearTripDataCheckinStart(),
                SessionActions.clearSessionDataCheckinStart(),
                ...actions
              ];
            }
            return actions;
          } else {
            return actions;
          }
        })
      ))
    )
  );

  getTripRouting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthTokenActions.checkinGetAuthTokenSuccess),
      switchMap(action => of(action).pipe(
        map(_ => {
          return TripActions.getByPnr({ tripSearch: action.tripSearch, alertMessage: '' });
        })
      ))
    )
  );

  getTripSuccessRouting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.getTripSuccessRouting),
      switchMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromTrip.getTrip))),
        map(([_, trip]) => {
          // Count the number of checkin-eligible segments
          let entries = trip?.results[0]?.segments?.entries;
          const isMultiSegment = entries?.length > 1;
          const canCheckInMultiple = entries?.filter(entry => entry?.canAllPassengersCheckIn).length > 1;
          const isInternational = entries?.some(entry => entry.marketType === Constants.marketTypeInternational);
          if (!canCheckInMultiple && !isInternational) {
            entries = entries?.filter(entry => entry?.canAllPassengersCheckIn);
          }

          // Multi segment trip w/ > 1 checkin-eligible or international trip with regulatory details inhibition.
          if (isMultiSegment && canCheckInMultiple) {
            return RouterActions.routeToSegmentSelector({ componentName: 'getTripSuccessRouting' });
            // Not multi segment but all passengers are not checked in.
          } else {
            return SessionActions.setSessionSelectedSegment({
              name: 'selectedSegment',
              value: entries[0],
              errorCode: null
            });
          }
        })
      ))
    )
  );

  selectedSegmentRouting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.setSelectedSegmentRouting),
      switchMap(action => of(action).pipe(
        map(_ => {
          const segment = action.segment;
          const areAllPassengersCheckedIn = segment.areAllPassengersCheckedIn;
          const componentName = action.componentName;
          if (areAllPassengersCheckedIn) {
            return RouterActions.routeToDashboard({ componentName });
          } else {
            return RouterActions.routeToPassengers({ componentName });
          }
        })
      ))
    )
  );

  routeToSegmentSelector$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToSegmentSelector),
      switchMap(action => of(action).pipe(
        map(_ => {
          const nextRoute = CHECKIN_ROUTES.ROUTE_SEGMENT_SELECTOR;
          const fromRoute = action.componentName;
          const routeToString = this.formatRouteToString(nextRoute.component, fromRoute);
          this.router.navigate([`/${nextRoute.route}`]);
          return RouterActions.routeToSuccess(routeToString)();
        })
      ))
    )
  );

  routeToHealthAttestation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToHealthAttestation),
      switchMap(action => of(action).pipe(
        concatMap(_ => {
          const nextRoute = CHECKIN_ROUTES.ROUTE_HEALTH_ATTESTATION;
          const fromRoute = action.componentName;
          const routeToString = this.formatRouteToString(nextRoute.component, fromRoute);
          const attestationActions = [
            RouterActions.routeToSuccess(routeToString)()
          ];
          this.router.navigate([`/${nextRoute.route}`]);
          return attestationActions;
        })
      ))
    )
  );

  routeToGroupBookingPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToGroupBookingPage),
      switchMap(action => of(action).pipe(
        map(_ => {
          this.router.navigate([`${CHECKIN_ROUTES.ROUTE_GROUP_CHECKIN.route}`]);
          return RouterActions.routeToSuccess
            (`Route To ${CHECKIN_ROUTES.ROUTE_GROUP_CHECKIN.component} from ${action.componentName} Success`)();
        })
      ))
    )
  );

  routeToPassengers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToPassengers),
      switchMap(action => of(action).pipe(
        concatMap(_ => {
          const nextRoute = CHECKIN_ROUTES.ROUTE_PASSENGER;
          const fromRoute = action.componentName;
          const routeToString = this.formatRouteToString(nextRoute.component, fromRoute);
          const passengerActions = [
            RouterActions.routeToSuccess(routeToString)()
          ];
          this.router.navigate([`/${nextRoute.route}`]);
          return passengerActions;
        })
      ))
    )
  );

  routeToInfant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToInfant),
      switchMap(action => of(action).pipe(
        map(_ => {
          const routeToString = this.formatRouteToString(CHECKIN_ROUTES.ROUTE_INFANT.component, action.componentName);
          this.router.navigate([`${CHECKIN_ROUTES.ROUTE_INFANT.route}`]);
          return RouterActions.routeToSuccess(routeToString)();
        })
      ))
    )
  );

  routeToInfantAdditionalInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToInfantAdditionalInfo),
      switchMap(action => of(action).pipe(
        map(_ => {
          const routeToString = this.formatRouteToString(CHECKIN_ROUTES.ROUTE_INFANT_ADDITIONAL_INFO.component, action.componentName);
          this.router.navigate([`${CHECKIN_ROUTES.ROUTE_INFANT_ADDITIONAL_INFO.route}`]);
          return RouterActions.routeToSuccess(routeToString)();
        })
      ))
    )
  );

  routeToMilitary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToMilitary),
      switchMap(action => of(action).pipe(
        map(_ => {
          this.router.navigate([`${CHECKIN_ROUTES.ROUTE_MILITARY.route}`]);
          return RouterActions.routeToSuccess(`Route To ${CHECKIN_ROUTES.ROUTE_MILITARY.component} from ${action.componentName} Success`)();
        })
      ))
    )
  );

  routeToCheckin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToCheckin),
      switchMap(action => of(action).pipe(
        map(_ => {
          const routeToString = this.formatRouteToString(CHECKIN_ROUTES.ROUTE_CHECKIN.component, action.componentName);
          this.router.navigate([`${CHECKIN_ROUTES.ROUTE_CHECKIN.route}`]);
          return RouterActions.routeToSuccess(routeToString)();
        })
      ))
    )
  );

  routeToDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToDashboard),
      switchMap(action => of(action).pipe(
        map(_ => {
          const routeToString = this.formatRouteToString(CHECKIN_ROUTES.ROUTE_DASHBOARD.component, action.componentName);
          this.router.navigate([`/${CHECKIN_ROUTES.ROUTE_DASHBOARD.route}`]);
          return RouterActions.routeToSuccess(routeToString)();
        })
      ))
    )
  );
  routeToResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToResult),
      withLatestFrom(this.store.pipe(select(fromSession.getSessionState))),
      switchMap(([action, state]) => of(action).pipe(
        concatMap(_ => {
          const actions = [RouterActions.routeToSuccess(`Route To ${CHECKIN_ROUTES.ROUTE_RESULT.component} from ${action.componentName} Success`)()];
          if ((action.data && action.data.callResultGetTrip) || state.os === Constants.ios) {
            actions.unshift(TripActions.resultGetTrip({ tripSearch: state.tripSearch }));
          }
          this.router.navigate([`${CHECKIN_ROUTES.ROUTE_RESULT.route}`]);
          return actions;
        })
      ))
    )
  );
  routeToGuidelines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToGuidelines),
      switchMap(action => of(action).pipe(
        map(_ => {
          const routeToString = this.formatRouteToString(CHECKIN_ROUTES.ROUTE_GUIDELINES.component, action.componentName);
          this.router.navigate([`${CHECKIN_ROUTES.ROUTE_GUIDELINES.route}`]);
          return RouterActions.routeToSuccess(routeToString)();
        })
      ))
    )
  );
  routeToSeatUpgradeList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToSeatUpgradeList),
      switchMap(action => of(action).pipe(
        map(_ => {
          const routeToString = this.formatRouteToString(CHECKIN_ROUTES.ROUTE_SEAT_UPGRADE_LIST.component, action.componentName);
          this.router.navigate([`${CHECKIN_ROUTES.ROUTE_SEAT.route}/${CHECKIN_ROUTES.ROUTE_SEAT_UPGRADE_LIST.route}`]);
          return RouterActions.routeToSuccess(routeToString)();
        })
      ))
    )
  );

  routeToSeatUpgrade$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToSeatUpgrade),
      switchMap(action => of(action).pipe(
        map(_ => {
          const routeToString = this.formatRouteToString(CHECKIN_ROUTES.ROUTE_SEAT_UPGRADE.component, action.componentName);
          this.router.navigate([`${CHECKIN_ROUTES.ROUTE_SEAT.route}/${CHECKIN_ROUTES.ROUTE_SEAT_UPGRADE.route}`]);
          return RouterActions.routeToSuccess(routeToString)();
        })
      ))
    )
  );

  seatUpgradeDefaultNavButtonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.defaultNavButtonClick(CHECKIN_ROUTES.ROUTE_SEAT_UPGRADE.route)),
      switchMap(action => of(action).pipe(
        map(_ => {
          return RouterActions.routeToSeats({ componentName: CHECKIN_ROUTES.ROUTE_SEAT_UPGRADE.component });
        })
      ))
    )
  );

  routeToLinkMinor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToLinMinorToAdultPage),
      switchMap(action => of(action).pipe(
        map(_ => {
          const routeToString = this.formatRouteToString(CHECKIN_ROUTES.ROUTE_MINOR.component, action.componentName);
          this.router.navigate([`${CHECKIN_ROUTES.ROUTE_MINOR.route}`]);
          return RouterActions.routeToSuccess(routeToString)();
        })
      ))
    )
  );

  actionRouting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.actionRouting),
      switchMap(action => of(action).pipe(
        withLatestFrom(
          this.store.pipe(select(fromRouter.getDashboardActions)),
          this.store.pipe(select(fromRouter.getSortedActions)),
          this.store.pipe(select(fromCart.cartState)),
          this.store.pipe(select(fromSession.getOSDevice)),
          this.store.pipe(select(fromCart.cartState)),
        ),
        concatMap(([_, dashboardActions, routerSortedActions, cartState, osDevice]) => {
          // Use existing sorted actions or Sort dashboardActions
          const sortedActions = !!routerSortedActions && !!routerSortedActions.length
            ? [...routerSortedActions]
            : [...dashboardActions].sort((a, b) => actionPrecedenceList[a].priority - actionPrecedenceList[b].priority)
              .map(a => actionPrecedenceList[a]);
          // Setup next checkin page flow
          const routerState = {
            previous: !!action.actions ? CHECKIN_ROUTES.ROUTE_DASHBOARD.route : sortedActions && sortedActions.shift().route,
            current: !!sortedActions[0] ? sortedActions[0].route : CHECKIN_ROUTES.ROUTE_RESULT.route,
            next: sortedActions[1] && sortedActions[1].route,
          };
          const routeToString = this.formatRouteToString(routerState.current, routerState.previous);
          const actions = [
            RouterActions.actionRoutingSuccess({ routerState, sortedActions }),
            RouterActions.routeToSuccess(routeToString)()
          ];

          // Navigate to next page
          if ((routerState.current === CHECKIN_ROUTES.ROUTE_RESULT.route ||
            routerState.current === actionPrecedenceList.BoardingPass.route)) {
            if (!!cartState?.cartItems?.length) {
              actions.push(RouterActions.routeToPayment({ componentName: "Action Routing" }));
            } else {
              if (cartState?.id) {
                actions.push(CartActions.deleteCart({ cartId: cartState.id }));
              }

              if (osDevice === Constants.ios) {
                actions.push(RouterActions.routeToResult({ componentName: "Action Routing", data: { callResultGetTrip: true } }));
              } else {
                actions.push(SessionActions.setSessionResultData({ name: "resultData", value: null }));
                this.router.navigate([`${routerState.current}`]);
              }
            }
          } else if (!!routerState.current) {
            this.router.navigate([`${routerState.current}`]);
          }
          return actions;
        })
      ))
    )
  );

  routeToSeat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToSeats),
      switchMap(action => of(action).pipe(
        map(_ => {
          const routeToString = this.formatRouteToString(CHECKIN_ROUTES.ROUTE_SEAT.component, action.componentName);
          this.router.navigate([`${CHECKIN_ROUTES.ROUTE_SEAT.route}`]);
          return RouterActions.routeToSuccess(routeToString)();
        })
      ))
    )
  );

  routeToPassengerInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToPassengerInformation),
      switchMap(action => of(action).pipe(
        map(_ => {
          this.router.navigate([`${CHECKIN_ROUTES.ROUTE_INTERNATIONAL_TRAVELER_SELECTION.route}`]);
          return RouterActions.routeToSuccess(`Route to ${CHECKIN_ROUTES.ROUTE_INTERNATIONAL_TRAVELER_SELECTION.component} from ${action.componentName} Success`)();
        })
      ))
    )
  );

  routeToIntlTravelerInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SecurityInfoActions.travelerSelectionAddInfo),
      concatMap(action => of(action).pipe(
        map(_ => {
          const routeToString = this.formatRouteToString(CHECKIN_ROUTES.ROUTE_INTERNATIONAL_TRAVELER_INFO.component, '[International Traveler Selection]');
          this.router.navigate([`${CHECKIN_ROUTES.ROUTE_INTERNATIONAL_TRAVELER_INFO.route}`]);
          return RouterActions.routeToSuccess(routeToString)();
        })
      ))
    )
  );

  routeToPassportInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToPassportInformation),
      concatMap(action => of(action).pipe(
        map(_ => {
          const routeToString = this.formatRouteToString(CHECKIN_ROUTES.ROUTE_PASSPORT_INFORMATION.component, action.componentName);
          this.router.navigate([`${CHECKIN_ROUTES.ROUTE_PASSPORT_INFORMATION.route}`]);
          return RouterActions.routeToSuccess(routeToString)();
        })
      ))
    )
  );


  routeToResidenceInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToResidenceInformation),
      concatMap(action => of(action).pipe(
        map(_ => {
          const routeToString = this.formatRouteToString(CHECKIN_ROUTES.ROUTE_RESIDENCE_INFORMATION.component, action.componentName);
          this.router.navigate([`${CHECKIN_ROUTES.ROUTE_RESIDENCE_INFORMATION.route}`]);
          return RouterActions.routeToSuccess(routeToString)();
        })
      ))
    )
  );

  routeToTravelInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToTravelInformation),
      concatMap(action => of(action).pipe(
        map(_ => {
          const routeToString = this.formatRouteToString(CHECKIN_ROUTES.ROUTE_TRAVEL_INFORMATION.component, action.componentName);
          this.router.navigate([`${CHECKIN_ROUTES.ROUTE_TRAVEL_INFORMATION.route}`]);
          return RouterActions.routeToSuccess(routeToString)();
        })
      ))
    )
  );

  routeToEmergencyContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToEmergencyContact),
      concatMap(action => of(action).pipe(
        map(_ => {
          const routeToString = this.formatRouteToString(CHECKIN_ROUTES.ROUTE_EMERGENCY_CONTACT.component, action.componentName);
          this.router.navigate([`${CHECKIN_ROUTES.ROUTE_EMERGENCY_CONTACT.route}`]);
          return RouterActions.routeToSuccess(routeToString)();
        })
      ))
    )
  );

  openCheckinConfirmationModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.openCheckinConfirmationModal),
      switchMap(action => of(action).pipe(
        tap(_ => {
          this.modalsService.openCheckinConfirmModal();
        })
      ))
    ), { dispatch: false }
  );

  routeToBaggage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToBaggage),
      switchMap(action => of(action).pipe(
        map(_ => {
          this.router.navigate([`${CHECKIN_ROUTES.ROUTE_BAGGAGE.route}`]);
          return RouterActions.routeToSuccess(`Route to ${CHECKIN_ROUTES.ROUTE_BAGGAGE.component} from ${action.componentName} Success`)();
        })
      ))
    )
  );

  routeToSeatMapById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToSeatMapById),
      switchMap(action => of(action).pipe(
        map(_ => {

          this.router.navigate(
            [`${CHECKIN_ROUTES.ROUTE_SEAT.route}`, `${CHECKIN_ROUTES.ROUTE_SEATMAP.route}`],
            {
              queryParams: action.queryParams
            }
          );
          return RouterActions.routeToSuccess(`Route to ${CHECKIN_ROUTES.ROUTE_SEATMAP.component} from ${action.componentName} Success`)();
        })
      ))
    )
  );

  routeToPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToPayment),
      switchMap(action => of(action).pipe(
        map(_ => {
          this.router.navigate([`/${CHECKIN_ROUTES.ROUTE_PAYMENT.route}`]);
          return RouterActions.routeToSuccess(`Route to ${CHECKIN_ROUTES.ROUTE_PAYMENT.component} from ${action.componentName} Success`)();
        })
      ))
    )
  );

  routeToBagAdvisory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToBagAdvisory),
      switchMap(action => of(action).pipe(
        map(_ => {
          this.router.navigate([`/${CHECKIN_ROUTES.ROUTE_BAGGAGE.route}`, `${CHECKIN_ROUTES.ROUTE_BAG_ADVISORY_ITEMS.route}`]);
          return RouterActions.routeToSuccess(`Route to ${CHECKIN_ROUTES.ROUTE_BAG_ADVISORY_ITEMS.component} from ${action.componentName} Success`)();
        })
      ))
    )
  );

  routeToBagSpecialItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToBagSpecialItems),
      switchMap(action => of(action).pipe(
        map(_ => {
          this.router.navigate([`${CHECKIN_ROUTES.ROUTE_BAGGAGE.route}`,
          `${CHECKIN_ROUTES.ROUTE_BAG_SPECIAL_ITEMS.route}`],
            { queryParams: action.queryParams.param });
          return RouterActions.routeToSuccess(`Route to ${CHECKIN_ROUTES.ROUTE_BAG_ADVISORY_ITEMS.component} from ${action.componentName} Success`)();
        })
      ))
    )
  );

  routeToBoardingPass$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToBoardingPass),
      switchMap(action => of(action).pipe(
        map(_ => {
          const nextRoute = CHECKIN_ROUTES.ROUTE_BOARDING_PASS_PAGE;
          const fromRoute = action.componentName;
          const routeToString = this.formatRouteToString(nextRoute.component, fromRoute);
          this.router.navigate([`/${CHECKIN_ROUTES.ROUTE_RESULT.route}/${nextRoute.route}`]);
          return RouterActions.routeToSuccess(routeToString)();
        })
      ))
    )
  );

  guidelinesDefaultNavButtonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.defaultNavButtonClick(CHECKIN_ROUTES.ROUTE_GUIDELINES.route)),
      switchMap(action => of(action).pipe(
        map(() => TripActions.checkIn())
      ))
    )
  );

  passengerDefaultNavButtonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.defaultNavButtonClick(CHECKIN_ROUTES.ROUTE_PASSENGER.route)),
      switchMap(action => of(action).pipe(
        withLatestFrom(
          this.store.pipe(select(fromTrip.isInternational)),
          this.store.pipe(select(fromSession.sessionSelectedSegment))
        ),
        concatMap(([_, isInternational, selectedSegment]) => {
          if (isInternational) {
            if (selectedSegment.contactTracingRequired) {
              return [RouterActions.routeToContactTracing({ componentName: CHECKIN_ROUTES.ROUTE_PASSENGER.component })];
            } else {
              return [
                InternationalDetailsActions.getMissingRegulatoryDetails({ componentName: CHECKIN_ROUTES.ROUTE_PASSENGER.component, skipRouting: true }),
                RouterActions.routeToPassportInformation({ componentName: CHECKIN_ROUTES.ROUTE_PASSENGER.component })
              ];
            }
          } else {
            return [RouterActions.routeToGuidelines({ componentName: CHECKIN_ROUTES.ROUTE_PASSENGER.component })];
          }
        })
      ))
    )
  );

  internationalTravelerSelectionDefaultNavButtonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.defaultNavButtonClick(CHECKIN_ROUTES.ROUTE_INTERNATIONAL_TRAVELER_SELECTION.route)),
      switchMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromSecurityInfo.getHasAllTravelDocs))),
        map(([_, hasAllTravelDocs]) => {
          if (hasAllTravelDocs) {
            return RouterActions.routeToGuidelines(
              { componentName: CHECKIN_ROUTES.ROUTE_INTERNATIONAL_TRAVELER_SELECTION.component });
          } else {
            return RouterActions.routeToSuccess(`[International Traveler Selection] No Travel Docs`)();
          }
        })
      ))
    )
  );

  openCancelCheckinConfirmationModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.openCancelConfirmationModal),
      switchMap(action => of(action).pipe(
        tap(_ => {
          this.modalsService.openQuitModal();
        })
      ))
    ), { dispatch: false }
  );

  passengerSecondaryNavButtonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.secondaryNavButtonClick(CHECKIN_ROUTES.ROUTE_PASSENGER.route)),
      switchMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromTrip.isInternational))),
        concatMap(([_, isInternational]) => {
          if (isInternational) {
            return [
              RouterActions.openCancelConfirmationModal({ componentName: CHECKIN_ROUTES.ROUTE_PASSENGER.component })
            ];
          } else {
            return [
              RouterActions.openCheckinConfirmationModal({ componentName: CHECKIN_ROUTES.ROUTE_PASSENGER.component })
            ];
          }
        })
      ))
    )
  );

  infantSecondaryNavButtonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.secondaryNavButtonClick(CHECKIN_ROUTES.ROUTE_INFANT.route)),
      switchMap(action => of(action).pipe(
        map(_ => {
          return RouterActions.routeToPassengers({ componentName: CHECKIN_ROUTES.ROUTE_INFANT.component });
        })
      ))
    )
  );

  attestationDefaultNavButtonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.defaultNavButtonClick(CHECKIN_ROUTES.ROUTE_HEALTH_ATTESTATION.route)),
      switchMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromTrip.getTrip))),
        concatMap(([_, trip]) => {
          const terms: Terms = {
            termsTitle: 'Covid19AcceptanceTerms',
            termsAcceptance: true
          };
          return [
            RouterActions.routeToPassengers({ componentName: CHECKIN_ROUTES.ROUTE_HEALTH_ATTESTATION.component }),
            TripActions.checkInfantStatus({ trip: trip.results[0] }),
            TripActions.checkInMinorStatus({ trip: trip.results[0] }),
            SessionActions.setSessionTermsAndAgreement({ terms })
          ];
        })
      ))
    )
  );

  militaryDefaultNavButtonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.defaultNavButtonClick(CHECKIN_ROUTES.ROUTE_MILITARY.route)),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromTrip.militaryPaxInformation)), this.store.pipe(select(fromTrip.getTripId)),
          this.store.pipe(select(fromRouter.getPreviousRoute))),
        tap(([_, militaryPaxInformation, tripId, previousRoute]) => {
          const noMilitaryTravelType = militaryPaxInformation.every(pax => pax.militaryTravelType === MilitaryTravelType.NONE);
          if (noMilitaryTravelType) {
            this.modalsService.openEmptyMilitaryTypeMessage(tripId, militaryPaxInformation, previousRoute);
          } else {
            this.modalsService.openMilitarySaveTypeMessage(
              tripId,
              militaryPaxInformation,
              previousRoute
            );
          }
        })
      )
      )), { dispatch: false }
  );

  militarySecondaryNavButtonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.secondaryNavButtonClick(CHECKIN_ROUTES.ROUTE_MILITARY.route)),
      switchMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromRouter.getPreviousRoute))),
        map(([_, previous]) => {
          if (CHECKIN_ROUTES.ROUTE_BAGGAGE.route.includes(previous)) {
            return RouterActions.routeToBaggage({ componentName: CHECKIN_ROUTES.ROUTE_MILITARY.component });
          } else {
            return RouterActions.routeToPassengers({ componentName: CHECKIN_ROUTES.ROUTE_MILITARY.component });
          }
        })
      ))
    ),
  );


  routeFromMilitarySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TripActions.removeMilitaryTravelTypeSuccess, TripActions.militaryTypeSuccessModalClosed),
      switchMap(action => of(action).pipe(
        map(_ => {
          this.router.navigate([`/${action.routeTo}`]);
          return RouterActions.routeToSuccess(
            `Routing to ${action.routeTo.toUpperCase()} Component After Successful Military Update`)();
        })
      ))
    )
  );

  seatDefaultNavButtonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.defaultNavButtonClick(CHECKIN_ROUTES.ROUTE_SEAT.route)),
      switchMap(action => of(action).pipe(
        map(_ => {
          return RouterActions.routeToBaggage({ componentName: CHECKIN_ROUTES.ROUTE_SEAT.route });
        })
      ))
    )
  );

  seatSecondaryNavButtonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.secondaryNavButtonClick(CHECKIN_ROUTES.ROUTE_SEAT.route)),
      switchMap(action => of(action).pipe(
        map(_ => {
          return RouterActions.routeToPayment({ componentName: CHECKIN_ROUTES.ROUTE_SEAT.route });
        })
      ))
    )
  );

  seatMapDefaultNavButtonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.defaultNavButtonClick(CHECKIN_ROUTES.ROUTE_SEATMAP.route)),
      switchMap(action => of(action).pipe(
        map(_ => {
          return CartActions.checkIfAllSeatsHasBeenUpgraded();
        })
      ))
    )
  );

  seatMapSecondaryNavButtonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.secondaryNavButtonClick(CHECKIN_ROUTES.ROUTE_SEATMAP.route)),
      switchMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromCart.cartState))),
        switchMap(([_, cartState]) => {
          const actions: Action[] = [RouterActions.routeToSeats({ componentName: CHECKIN_ROUTES.ROUTE_SEATMAP.route })];

          if (cartState.cartItems !== cartState.storedItems) {
            actions.unshift(CartActions.restoreCartItems());
          }

          return actions;
        })
      ))
    )
  );

  baggageDefaultNavButtonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.defaultNavButtonClick(CHECKIN_ROUTES.ROUTE_BAGGAGE.route)),
      withLatestFrom(
        this.store.pipe(select(fromCart.bagItems)),
      ),
      map(([_, bagItems]) => {
        if (bagItems.length > 0) {
          return CartActions.openBagsPriceConfirmationModal();
        } else {
          return RouterActions.routeToPayment({ componentName: CHECKIN_ROUTES.ROUTE_BAGGAGE.component });
        }
      })
    )
  );

  specialItemsDefaultNavButtonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.defaultNavButtonClick(CHECKIN_ROUTES.ROUTE_BAG_SPECIAL_ITEMS.route)),
      switchMap(action => of(action).pipe(
        map(_ => {
          return RouterActions.routeToBaggage({ componentName: CHECKIN_ROUTES.ROUTE_BAG_SPECIAL_ITEMS.component });
        })
      ))
    )
  );

  advisoryItemsDefaultNavButtonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.defaultNavButtonClick(CHECKIN_ROUTES.ROUTE_BAG_ADVISORY_ITEMS.route)),
      switchMap(action => of(action).pipe(
        map(_ => {
          return RouterActions.routeToBaggage({ componentName: CHECKIN_ROUTES.ROUTE_BAG_ADVISORY_ITEMS.component });
        })
      ))
    )
  );

  internationTravelerInfoDefaultNavButtonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.defaultNavButtonClick(CHECKIN_ROUTES.ROUTE_INTERNATIONAL_TRAVELER_INFO.route)),
      switchMap(action => of(action).pipe(
        map(_ => {
          return SecurityInfoActions.travelerInfoPostSecurity();
        })
      ))
    )
  );

  internationalTravelerInfoSecondaryNavButtonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.secondaryNavButtonClick(CHECKIN_ROUTES.ROUTE_INTERNATIONAL_TRAVELER_INFO.route)),
      switchMap(action => of(action).pipe(
        map(_ => {
          return RouterActions.routeToPassengerInformation({
            componentName: CHECKIN_ROUTES.ROUTE_INTERNATIONAL_TRAVELER_INFO.component
          });
        })
      ))
    )
  );

  passengerInfoSecondaryNavButtonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.secondaryNavButtonClick(CHECKIN_ROUTES.ROUTE_INTERNATIONAL_TRAVELER_SELECTION.route)),
      switchMap(action => of(action).pipe(
        map(_ => {
          return RouterActions.routeToCheckin({ componentName: CHECKIN_ROUTES.ROUTE_INTERNATIONAL_TRAVELER_SELECTION.component });
        })
      ))
    )
  );

  infantInfoSecondaryNavButtonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.secondaryNavButtonClick(CHECKIN_ROUTES.ROUTE_INFANT_ADDITIONAL_INFO.route)),
      switchMap(action => of(action).pipe(
        map(_ => {
          return RouterActions.routeToInfant({ componentName: CHECKIN_ROUTES.ROUTE_INFANT_ADDITIONAL_INFO.component });
        })
      ))
    )
  );

  passportInformationDefaultNavButtonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.defaultNavButtonClick(CHECKIN_ROUTES.ROUTE_PASSPORT_INFORMATION.route)),
      switchMap(action => of(action).pipe(
        map(_ => InternationalDetailsActions.postPassportInformation())
      ))
    )
  );

  contactTracingDefaultNavButtonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.defaultNavButtonClick(CHECKIN_ROUTES.ROUTE_CONTACT_TRACING.route)),
      switchMap(action => of(action).pipe(
        concatMap(_ => {
          return [InternationalDetailsActions.getMissingRegulatoryDetails({ componentName: CHECKIN_ROUTES.ROUTE_CONTACT_TRACING.component, skipRouting: true }),
          RouterActions.routeToPassportInformation({ componentName: CHECKIN_ROUTES.ROUTE_CONTACT_TRACING.component })];
        })
      ))
    )
  );

  contactTracingSecondaryNavButtonClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.secondaryNavButtonClick(CHECKIN_ROUTES.ROUTE_CONTACT_TRACING.route)),
      switchMap(action => of(action).pipe(
        map(_ => {
          return RouterActions.routeToPassengers({ componentName: CHECKIN_ROUTES.ROUTE_CONTACT_TRACING.component });
        })
      ))
    )
  );

  routeToContactTracing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.routeToContactTracing),
      concatMap(action => of(action).pipe(
        map(_ => {
          const routeToString = this.formatRouteToString(CHECKIN_ROUTES.ROUTE_CONTACT_TRACING.component, action.componentName);
          this.router.navigate([`${CHECKIN_ROUTES.ROUTE_CONTACT_TRACING.route}`]);
          return RouterActions.routeToSuccess(routeToString)();
        })
      ))
    )
  );

  constructor(
    private modalsService: ModalsService,
    private router: Router,
    private actions$: Actions,
    private store: Store<EntityState>,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  private formatRouteToString(nextRoute: string, fromRoute: string) {
    return `Route To ${nextRoute} from ${fromRoute} Success`;
  }
}
