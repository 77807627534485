import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { CartSelectors } from '~app/store/services/cart/cart.selectors';
import { CartItem } from '~app/models/cartitem.model';
import { CATALOG_ID_SEAT, CATALOG_ID_BAG, CATALOG_ID_SEAT_AMADEUS } from '~app/store/services/cart/cart.service';
import { CartContentItems } from '~app/models/cartcontentItems.model';
import { CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';
import { RouterDispatchers, SessionSelectors } from '~app/store';
@Component({
    selector: 'app-payment-cart',
    templateUrl: './payment-cart.component.html',
    styleUrls: ['./payment-cart.component.scss'],
})
export class PaymentCartComponent implements OnInit, OnDestroy {
    @Input() route: '';
    baggageRoute = CHECKIN_ROUTES.ROUTE_BAGGAGE.route;
    catalogIdSeat = CATALOG_ID_SEAT;
    catalogIdSeatAmadeus = CATALOG_ID_SEAT_AMADEUS;
    catalogIdBag = CATALOG_ID_BAG;
    regularBagsTitle = "Regular Bags";
    specialBagsTitle = "Special Bags";
    regularBagProductId = "0GO";
    hasSeatsSelected: Boolean;
    hasBagsSelected: Boolean;
    cartResultItems$: Observable<CartItem[]>;
    subscriptions = new Subscription();
    cartResultItems: CartItem[];
    // New cart content service
    cartContentItemsSub: Subscription;
    cartContentItems$: Observable<CartContentItems[]>;
    cartContentItems: CartContentItems[];

    constructor(
        private cartSelectors: CartSelectors,
        private sessionSelectors: SessionSelectors,
        public routerDispatchers: RouterDispatchers,
    ) {
        this.cartResultItems$ = this.cartSelectors.cartResultItems$;
        this.cartContentItems$ = this.sessionSelectors.cartContent$;
    }

    ngOnInit() {
        this.cartContentItemsSub = this.cartContentItems$
            .subscribe(contentItems => {
                if (!!contentItems) {
                    this.cartContentItems = contentItems;
                }
                if (!!this.cartContentItemsSub) {
                    this.cartContentItemsSub.unsubscribe();
                }
            });
        this.subscriptions.add(this.cartResultItems$.subscribe(cartItems => {
            if (cartItems) {
                this.cartResultItems = this.creatCartList(cartItems).map(cartItem => ({
                    ...cartItem,
                    title: this.getCartContent(cartItem.productId, cartItem.catalogId)
                }));
                this.hasSeatsSelected = this.cartResultItems.some(isSeatPresent => isSeatPresent.catalogId === CATALOG_ID_SEAT || isSeatPresent.catalogId === CATALOG_ID_SEAT_AMADEUS);
                this.hasBagsSelected = this.cartResultItems.some(isBagPresent => isBagPresent.catalogId === CATALOG_ID_BAG);
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    getCartContent(code: string, catalogId: string) {
        if (catalogId === CATALOG_ID_BAG) {
            if (code?.includes(this.regularBagProductId)) {
                return this.regularBagsTitle;
            } else {
                return this.specialBagsTitle;
            }
        } else {
            return this.cartContentItems.find(item => item.code === (code?.split("-")?.[2] || 'Seat-change'))?.title;
        }
    }

    creatCartList(cartItems: CartItem[]): CartItem[] {
        let accumulatorIndex;
        return Object.values(cartItems.filter(cartResultItem =>
            (cartResultItem.catalogId === CATALOG_ID_BAG || cartResultItem.catalogId === CATALOG_ID_SEAT || cartResultItem.catalogId === CATALOG_ID_SEAT_AMADEUS))
            .reduce((accumulator, current) => {
                if (current.catalogId === CATALOG_ID_BAG && !current.productId?.includes(this.regularBagProductId)) {
                    accumulatorIndex = this.specialBagsTitle;
                } else {
                    accumulatorIndex = current.productId;
                }
                accumulator[accumulatorIndex] = accumulator[accumulatorIndex] ||
                    { ...current, quantity: 0, price: 0, priceDifference: 0 };
                accumulator[accumulatorIndex].quantity += current.quantity;
                accumulator[accumulatorIndex].price += Number(current.price);
                accumulator[accumulatorIndex].priceDifference += Number(current.priceDifference);
                return accumulator;
            }, {})
        );
    }
}
