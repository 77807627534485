import {
  CART_STATE_PROCESSING,
  CartService,
  CATALOG_ID_SEAT,
  RESP_TYPE_PARTIAL_SUCCESS,
} from '~app/store/services/cart/cart.service';
import { CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';
import { ModalsService } from '~app/modals/modals.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SessionDispatchers } from '~app/store/services/session/session.dispatchers';
import * as CartActions from '~app/store/actions/cart.actions';
import * as SessionActions from '~app/store/actions/session.actions';
import { RelatedCart } from '~app/models/relatedcart.model';
import { Cart } from '~app/models/cart.model';
import { Action, select, Store } from '@ngrx/store';
import { EntityState } from '~app/store/reducers';
import * as fromCart from '~app/store/services/cart/cart.selectors';
import * as fromReferenceData from '~app/store/services/reference-data/reference-data.selectors';
import { SeatAssignment } from '~app/models/seatassignment';
import * as fromSession from '../../services/session/session.selectors';
import * as CatalogActions from '../../actions/catalog.actions';
import * as RouterActions from '~app/store/actions/router.actions';
import * as OrderActions from '~app/store/actions';
import { HelperService } from '~app/services/helper.service';
import { ICartState } from '~app/store/reducers/cart/cart.reducers';
import { SeatClassType } from '~app/constants/ha-constants';
import { RegexPatterns } from '~app/constants/regex-constants';
import { AEPEventNames } from '~app/services/checkin-tagging-data.service';
import { TaggingService } from '@hawaiianair/tagging';

export const ERR_CODE_SEAT_UNAVAILABLE = 422;
export const ERR_CODE_SEAT_UNAVAILABLE2 = 500;

const STR_SUCCESS = ' Success';
const STR_FAILURE = ' Failure';

@Injectable()
export class CartEffects {
  constructor(
    private actions$: Actions,
    private cartService: CartService,
    private sessionDispatchers: SessionDispatchers,
    private modalsService: ModalsService,
    private store: Store<EntityState>,
    private helperService: HelperService,
    private taggingService: TaggingService,
  ) { }

  createCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.createCartPassengers, CartActions.createCartDashboard),
      withLatestFrom(this.store.pipe(select(fromCart.cartState))),
      switchMap(([action, cartState]) =>
        this.cartService.create(action.segmentId, action.passengerIds).pipe(
          tap(
            response => {
              if (!!response.results && response.results.length > 0) {
                const cart = response.results[0];
                if (this.isCartInProgress(cartState, cart)) {
                  throw this.getRelatedCart(cart.relatedCarts[0]);
                }
              }
            }
          ),
          concatMap(cart => {
            // If the cart is already created then get the id from relatedCarts, otherwise get it from cart response
            const isCartAlreadyExist = !!cart.results[0].relatedCarts;
            let cartId: string;
            if (isCartAlreadyExist) {
              cartId = cart.results[0].relatedCarts[0].id;
              return ([
                SessionActions.setSessionCartId({ name: 'cartId', value: cartId }),
                CartActions.getCartAfterCreate({ cartId: cartId, route: action.route })
              ]);
            }
            else {
              cartId = cart.results[0].id;
              return ([
                SessionActions.setSessionCartId({ name: 'cartId', value: cartId }),
                CartActions.getCartAfterCreate({ cartId: cartId, route: action.route }),
                CartActions.refreshCartAfterCreate({ cartId })
              ]);
            }
          }),
          catchError(error => this.handleCartError(error, action.type, action.route))
        ))
    )
  );

  deleteCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.deleteCart),
      concatMap(action =>
        this.cartService.delete(action.cartId).pipe(
          map(res => CartActions.deleteCartSuccess({ res })),
          catchError(error => of(CartActions.deleteCartError({ error: error })))
        ))
    )
  );

  getCartError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.getCartError),
      tap(action => this.modalsService.openGenericErrorMessage(action.error))
    ), { dispatch: false }
  );

  deleteCartError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.deleteCartError),
      tap(_ => {
        // log it and do not take any further action possibly can block users from checking-in
      }),
    ), { dispatch: false }
  );

  recreateCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.recreateCartPassengers, CartActions.recreateCartDashboard),
      concatMap(action =>
        this.cartService.recreate(action.cartId, action.segmentId, action.passengerId).pipe(
          concatMap(cart => {
            return ([
              SessionActions.setSessionCartId({ name: 'cartId', value: cart.results[0].id }),
              CartActions.cartApiSuccess(action.type + STR_SUCCESS)({ cart: cart, route: action.route })
            ]);
          }),
          catchError(error => {
            return this.handleCartError(error, action.type, action.route);
          })
        ))
    )
  );

  getCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.getCartBagsComponent, CartActions.getCartAfterCreate),
      switchMap(action =>
        this.cartService.get(action.cartId).pipe(
          map(
            cart => {
              if (action.route === CHECKIN_ROUTES.ROUTE_DASHBOARD.route) {
                return CartActions.getCartSuccessDashboard({ cart });
              } else {
                return CartActions.getCartSuccessPassengers({ cart });
              }
            }
          ),
          catchError(error => {
            return this.handleCartError(error, action.type);
          })
        ))
    )
  );

  getCartWithItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.getCartWithBags, CartActions.getCartWithSeats, CartActions.replaceAllItems),
      switchMap(action =>
        this.cartService.get(action.cartId).pipe(
          tap(
            response => {
              if (!!response.results && response.results.length) {
                const cart = response.results[0];
                if (!!cart.relatedCarts && cart.relatedCarts.length) {
                  throw this.getRelatedCart(cart.relatedCarts[0]);
                }
              }
            }
          ),
          map(
            response => {
              const payload = {
                cartId: action.cartId,
                cart: response,
                cartItem: action.cartItems,
                catalogId: action.catalogId,
                itemType: action.itemType
              };
              return CartActions.updateCartWithItems(`${action.component} Update Cart With Items`)(payload);
            }
          ),
          catchError(error => {
            return this.handleCartError(error, action.type);
          })
        ))
    )
  );

  restoreCartItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.restoreCartItems),
      switchMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromCart.cartState))),
        map(([_, cartState]) => {
          return CartActions.replaceAllItems({
            cartId: cartState.id,
            cartItems: cartState.storedItems, catalogId: CATALOG_ID_SEAT, itemType: 'seats', component: '[Seatmap Component]'
          });
        })
      ))
    )
  );

  checkoutRequestFromComponents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.checkoutRequestFromPaymentRoute),
      withLatestFrom(this.store.pipe(select(fromSession.getSessionState))),
      switchMap(([action, state]) =>
        this.cartService.get(state.cartId).pipe(
          tap(
            response => {
              if (!!response.results && response.results.length) {
                const cart = response.results[0] as Cart;

                if (cart.id !== state.cartId) {
                  throw { error: 'different cart id', response };
                }

                if (!!cart.relatedCarts && cart.relatedCarts.length) {
                  throw this.getRelatedCart(cart.relatedCarts[0]);
                }
              } else {
                // TODO
                console.warn('checkout success reponse with no data');
              }
            }
          ),
          concatMap(
            response => {
              // get all items in the cart
              const theCart = response.results[0] as Cart;
              if (!!theCart.grandTotal) {
                return [CartActions.checkoutRequestProcessed()];
              } else if (!!theCart.items.length) {
                return of(OrderActions.orderFulfill({}));
              }
              else {
                return [
                  CartActions.deleteCart({ cartId: state.cartId }),
                  RouterActions.routeToResult({ componentName: CHECKIN_ROUTES.ROUTE_PAYMENT.component })
                ];
              }
            }
          ),
          catchError(error => {
            if (error.error === 'different cart id') {
              return of(CartActions.checkoutCartFailure(action.type + STR_FAILURE)({ error, cartId: state.cartId }));
            } else {
              return this.handleCartError(error, action.type);
            }
          })
        )
      )
    )
  );

  checkoutCartEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.checkoutCartFromPaymentRoute),
      withLatestFrom(
        this.store.pipe(select(fromReferenceData.getStationCodePointOfSale)),
        this.store.pipe(select(fromSession.getSessionState))
      ),
      switchMap(([action, pointOfSale, state]) =>
        this.cartService.checkout(state.code, state.cartId, action.paymentItem, pointOfSale).pipe(
          tap(res => {
            if (!res || !res.results || !res.results.length) {
              throw { error: 'checkout success response has no data', requestData: res };
            } else if (res.type === RESP_TYPE_PARTIAL_SUCCESS) {
              this.modalsService.openPartialSuccessModal(res.results
                .filter(rslt => !!rslt.rangeReference && rslt.status !== 200)
                .map(rslt => rslt.rangeReference));
            }
          }),
          withLatestFrom(this.store.pipe(select(fromCart.cart))),
          concatMap(([response, cart]) => {
            return [
              CartActions.checkoutCartSuccess(action.type + STR_SUCCESS)({ response, cart }),
              CartActions.deleteCart({ cartId: state.cartId }),
              RouterActions.routeToResult({ componentName: CHECKIN_ROUTES.ROUTE_PAYMENT.component, data: { callResultGetTrip: true } })
            ];
          }),
          catchError(error => {
            return of(CartActions.checkoutCartFailure(action.type + STR_FAILURE)({ error, cartId: state.cartId }));
          }),
        )
      )
    )
  );

  checkoutCartFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.checkoutCartFromPaymentRouteFailure),
      switchMap(action => of(action).pipe(
        tap(_ => { this.modalsService.openGenericErrorMessage({ errResponse: action.error, error: '' }); }),
        map(_ => CartActions.deleteCart({ cartId: action.cartId }))
      ))
    )
  );

  checkSeatCabinUpgradeForPaxes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.checkIfAllSeatsHasBeenUpgraded),
      switchMap(action => of(action).pipe(
        withLatestFrom(
          this.store.pipe(select(fromSession.getSessionState))),
        tap(([_, sessionState]) => {
          sessionState.segmentFlights.map(currentFlightSegment => {
            const seatAssignmentsByFlight: SeatAssignment[] = sessionState.recentlySavedSeats.filter(
              recentlySavedSeat => recentlySavedSeat.flightId === currentFlightSegment.id);
            const hasUpgradedCabin = seatAssignmentsByFlight.some(
              findFirstClass => findFirstClass.seatCategory === (SeatClassType.First_Class || SeatClassType.Business));
            if (hasUpgradedCabin) {
              const allHaveUpgradedCabin = seatAssignmentsByFlight.every(
                checkAllPaxForFirstClass =>
                  checkAllPaxForFirstClass.seatCategory === (SeatClassType.First_Class || SeatClassType.Business));
              if (!allHaveUpgradedCabin) {
                throw { error: 'Upgrade all passengers to the same cabin' };
              }
            }
          });
        }),
        map(() => {
          this.taggingService.trackEvent(AEPEventNames.SAVE_SEATS);
          return RouterActions.routeToSeats({ componentName: CHECKIN_ROUTES.ROUTE_SEAT.component });
        }),
        catchError(error => {
          this.modalsService.openUpgradeAllSeatsDialog();
          return of(CatalogActions.upGradeAllPaxToSameCabinError(error));
        })
      ))
    )
  );

  openBagsPriceConfirmationModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.openBagsPriceConfirmationModal),
      withLatestFrom(
        this.store.pipe(select(fromCart.bagsTotalCost))),
      tap(() => {
        this.modalsService.openBagPriceConfirmationModal();
      })
    ), { dispatch: false }
  );

  confirmBagsPrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.confirmBagsPrices),
      map(() => {
        return RouterActions.routeToPayment({ componentName: CHECKIN_ROUTES.ROUTE_BAGGAGE.route });
      })
    ),
  );

  updateCartWithItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.updateCartByRemovingASeatItem,
        CartActions.updateCartWithItems('[Bags Form Component] Update Cart With Items'),
        CartActions.updateCartWithItems('[Seatmap Component] Update Cart With Items'),
        CartActions.updateCartWithItems('[Bags Price Confirmation Component] Update Cart With Items')
      ),
      switchMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromCart.cartItems))),
        switchMap(([_, cartItems]) =>
          this.cartService.update(action.cartId, cartItems).pipe(
            switchMap(cart => {
              const actions: Action[] = [
                CartActions.updateCartWithItemsSuccess({ cart }),
                SessionActions.setMostRecentlySavedSeats()
              ];
              if (action?.type.includes('[Bags Price Confirmation Component]')) {
                actions.push(CartActions.confirmBagsPrices());
              }
              return actions;
            }),
            catchError(error => {
              return this.handleCartError(error, action.type);
            })
          ))
      ))
    )
  );

  militaryUpdateCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.militaryUpdateCart),
      switchMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromCart.cartState))),
        switchMap(([_, cartState]) => {
          if (!cartState.cartItems.length) {
            return [CatalogActions.bagsGetCatalog()] as Action[];
          }
          return this.cartService.update(cartState.id, cartState.cartItems).pipe(
            switchMap(cart => [CartActions.updateCartWithItemsSuccess({ cart }), CatalogActions.bagsGetCatalog()] as Action[]),
            catchError(error => {
              return this.handleCartError(error, action.type);
            })
          );
        })
      ))
    )
  );

  setMostRecentleySavedSeats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.setMostRecentlySavedSeats),
      switchMap(action => of(action).pipe(
        withLatestFrom(
          this.store.pipe(select(fromCart.seatItems)),
          this.store.pipe(select(fromSession.originallyAssignedSeats)),
          this.store.pipe(select(fromSession.selectedPassengers))),
        map(([_, fromCartSeats, fromCartAssignedSeats, selectedPassengers]) => {
          const cartSeats = fromCartSeats.map(item => {
            const pax = selectedPassengers.find(passenger => passenger.id === item.associatedPassenger.passengerId);
            return ({
              paxId: item.associatedPassenger.passengerId,
              flightId: item.associatedPassenger.flightId,
              seatId: item.assignment ? item.assignment : item.seat,
              paxName: pax ? pax.passengerName : null,
              seatCategory: this.helperService.setSeatCategory(item.seatCategory),
            });
          });

          const findNonUpdatedSavedSeats: SeatAssignment[] = fromCartAssignedSeats.map((seat: SeatAssignment) => {
            if (!cartSeats.find((cartSeat: any) => cartSeat.paxId === seat.paxId && cartSeat.flightId === seat.flightId)) {
              return seat;
            }
          }).filter(seat => !!seat);

          const seats = (fromCartAssignedSeats.length === cartSeats.length)
            ? cartSeats
            : [...findNonUpdatedSavedSeats, ...cartSeats];
          let recentlySavedSeats = seats.filter(seat => selectedPassengers.find(pax => pax.id === seat.paxId));
          recentlySavedSeats = recentlySavedSeats &&
            recentlySavedSeats.sort((a, b) => a.paxName.firstName.localeCompare(b.paxName.firstName));
          return SessionActions.setRecentlySavedSeatsSuccess({ recentlySavedSeats });
        })
      ))
    )
  );

  handleCartError(error: any, actionType: string, route?: string): Observable<any> {
    const seatmapModalActionTypes = [CartActions.getCartSeatmapModal.type, '[Seatmap Component] Update Cart With Items'];
    const amadeusRelatedCartError = error?.error?.errors?.[0]?.info;
    // check if the error message includes a cart Id.
    const amadeusRelatedCart = amadeusRelatedCartError?.includes(amadeusRelatedCartError?.match(RegexPatterns.duplicateCartId1A));
    if (amadeusRelatedCart) {
      const amadeusRelatedCartId = amadeusRelatedCartError.match(RegexPatterns.duplicateCartId1A)[0];
      this.sessionDispatchers.setSessionCartId('cartId', amadeusRelatedCartId);
      this.modalsService.openCheckInInProgressDialog(actionType, route);
      return of(CartActions.createCartErrCheckinInProgress({ error: error }));
    }
    // check if the error is an instance of RelatedCart
    if (error instanceof RelatedCart) {
      const relatedCart = error as RelatedCart;
      this.sessionDispatchers.setSessionCartId('cartId', relatedCart.id);
      if (relatedCart.state === CART_STATE_PROCESSING) {
        this.modalsService.openPaymentInProgressDialog(actionType);
        return of(CartActions.createCartErrPaymentInProgress({ error: error }));
      } else {
        this.modalsService.openCheckInInProgressDialog(actionType, route);
        return of(CartActions.createCartErrCheckinInProgress({ error: error }));
      }
    } else {
      // Session Ended
      if (!!error.status && error.status === 404) {
        this.modalsService.openSessionEndedConcurrencyModal();
        return of(CartActions.createCartErrSessionEnded({ error: error }));
        // seat unavailable
      } else if (!!error.status && (seatmapModalActionTypes.includes(actionType)) // seatmap case only
        && (error.status === ERR_CODE_SEAT_UNAVAILABLE || error.status === ERR_CODE_SEAT_UNAVAILABLE2)) {
        this.modalsService.openSeatUnavailableDlg();
        // generic error
      } else {
        this.modalsService.openGenericErrorMessage(error);
        return of(CartActions.cartApiError(`${actionType} Error`)({ error: error }));
      }
    }
  }

  private getRelatedCart(relatedCart: RelatedCart): RelatedCart {
    const theRelatedCart = new RelatedCart();
    theRelatedCart.id = relatedCart.id;
    theRelatedCart.segmentId = relatedCart.segmentId;
    theRelatedCart.state = relatedCart.state;
    !!relatedCart.channel ? theRelatedCart.channel = relatedCart.channel : theRelatedCart.channel = null;
    theRelatedCart.associatedPassengers = relatedCart.associatedPassengers.map(pax => pax);
    return theRelatedCart;
  }

  private isCartInProgress(currentCartState: ICartState, newCart: Cart): boolean {
    const isCartAlreadyExist = !!newCart.relatedCarts && newCart.relatedCarts.length > 0;
    const isCartCreatedOutsideApp = isCartAlreadyExist && currentCartState.cart === null;
    const isDifferentCartCreatedWithinApp = isCartAlreadyExist &&
      currentCartState.cart !== null && currentCartState.id !== newCart.relatedCarts[0].id;
    return isCartCreatedOutsideApp || isDifferentCartCreatedWithinApp;
  }
}
