import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subscription, throwError, timer } from 'rxjs';
import { BaseService } from '../base.service';
import { environment } from '~environments/environment';
import { SessionSelectors } from '../session/session.selectors';
import { CurrentLocaleService } from '@hawaiianair/core';
import { PassportScanInit } from '~app/models/passportscaninit.model';
import { PassportScanStatus } from '~app/models/passportscanstatus.model';
import { PassportScanData, PassportScanDocument } from '~app/models/passportscandata.model';
import { tap, catchError, retryWhen, mergeMap, take } from 'rxjs/operators';
import { Response } from '~app/models/response.model';
import { ReferenceDataSelectors } from '../reference-data/reference-data.selectors';
import { TripSelectors } from '../trip/trip.selectors';
import { PassengerList } from '~app/models/passengerlist.model';
import { Countries } from '~app/store/reducers/reference-data/reference-data.reducer';
import { PassportScanAttempts } from '~app/models/passportscanattempts.model';
import { InternationalDetailsSelectors } from '../international-details/international-details.selectors';
import { InternationalPassenger } from '~app/store/reducers/international details/international-details.reducers';
export interface PassportScanService {
  initiate(paxId: string): Observable<Response<PassportScanInit>>;
  getPassportScanStatus(scanReference: string): Observable<Response<PassportScanStatus>>;
  getScanData(scanReference: string): Observable<Response<PassportScanData>>;
  getScanAttempts(): Observable<Response<PassportScanAttempts>>;
  postScanAttempt(): Observable<Response<PassportScanAttempts>>;
}

enum LocaleLanguageMap {
  "zh" = "zh-CN",
  "zh-hant" = "zh-HK",
  "ja" = "ja",
  "ko" = "ko"
}
const titles = ['MRS', 'MR', 'MS', 'MASTER', 'MISS', 'MX', 'MADAM'];
const maxScanAttempts = 3;

@Injectable({
  providedIn: 'root'
})

export class PassportScanService extends BaseService implements PassportScanService {
  tripPassengersList$: Observable<PassengerList>;
  countries$: Observable<Countries>;
  subscription: Subscription;
  countriesSubscription: Subscription;
  tripPaxSubscription: Subscription;

  constructor(
    http: HttpClient,
    sessionSelectors: SessionSelectors,
    private currentLocaleService: CurrentLocaleService,
    private referenceDataSelectors: ReferenceDataSelectors,
    private tripSelectors: TripSelectors,
    private internationalDetailsSelectors: InternationalDetailsSelectors,
  ) {
    super(http, sessionSelectors);
    this.countries$ = this.referenceDataSelectors.countries$;
    this.tripPassengersList$ = this.tripSelectors.tripPassengersList$;
  }

  initiate(paxId: string): Observable<Response<PassportScanInit>> {
    const url = `${environment["exp-web-checkin-v2-api"]}/idVerification/initiate`;
    const body = {
      "customerInternalReference": environment["customerInternalReference"],
      "userReference": paxId,
      "reportingCriteria": "WCI-" + paxId,
      "workflowId": 101,
      "locale": this.currentLocaleService.language in LocaleLanguageMap ? LocaleLanguageMap[this.currentLocaleService.language] : 'en',
      "presets": [{
        "index": 1,
        "country": "USA",
        "type": "PASSPORT"
      }]
    };
    return this.http.post<Response<PassportScanInit>>(url, body);
  }

  getPassportScanStatus(scanReferenceId: string): Observable<Response<PassportScanStatus>> {
    const url = `${environment["exp-web-checkin-v2-api"]}/idVerification/status/${scanReferenceId}`;
    return this.http.get<Response<PassportScanStatus>>(url).pipe(
      tap(res => {
        if (res.results[0].status === 'DONE') {
          return res;
        } else {
          throw { error: 'scan status not done', requestData: res };
        }
      }),
      retryWhen(err$ => err$.pipe(
        mergeMap((err: any, i: number) => i > 10 ? throwError(err) : timer(1000))
      )),
      catchError(err => { throw err; })
    );
  }

  getScanData(scanReferenceId: string): Observable<Response<PassportScanData>> {
    const url = `${environment["exp-web-checkin-v2-api"]}/idVerification/data/${scanReferenceId}`;
    return this.http.get<Response<PassportScanData>>(url).pipe(
      tap(passport => this.validatePassportData(passport)),
      catchError(err => { throw err; })
    );
  }

  getMockScanData(passportDocument: PassportScanDocument): Observable<Response<PassportScanData>> {
    let passport: Response<PassportScanData>;
    if (passportDocument) {
      passport = {
        ...require('~mock/json/passportScanData.json'),
        results: [{
          ...require('~mock/json/passportScanData.json').results[0],
          document: passportDocument
        }]
      };
    }
    return of(passport).pipe(
      tap(passport => this.validatePassportData(passport)),
      catchError(err => { throw err; })
    );
  }

  private validatePassportData(passport: Response<PassportScanData>): void {
    const paxInTrip = this.getPaxInfo();
    const passportData = passport?.results?.[0];
    if (!passportData) {
      throw { error: 'no data', requestData: passport };
    } else if (passportData.document.status !== 'APPROVED_VERIFIED') {
      throw { error: passportData.document.status, requestData: passport };
    } else if (!this.correctPassport(passportData, paxInTrip)) {
      throw { error: 'wrong pax passport', requestData: passport };
    } 
    return;
  }

  getScanAttempts(): Observable<Response<PassportScanAttempts>> {
    const paxesIDs = this.getTripPassengersListIDs().join();
    const url = `${environment["exp-web-checkin-v2-api"]}/idVerification/scanAttempts?passengerIds=${paxesIDs}`;
    return this.http.get<Response<PassportScanAttempts>>(url).pipe(
      tap(response => {
        if (response.results.some(pax => pax.scanAttempts >= maxScanAttempts)) {
          throw { error: 'max scan attempts', requestData: response };
        }
      }),
      catchError(err => { throw err; })
    );
  }

  postScanAttempt(): Observable<Response<PassportScanAttempts>> {
    const paxId = this.getPassengerId();
    const url = `${environment["exp-web-checkin-v2-api"]}/idVerification/scanAttempts/${paxId}`;
    return this.http.post<Response<PassportScanAttempts>>(url, null).pipe(
      tap(response => {
        if (response?.results?.some(pax => pax.scanAttempts >= maxScanAttempts)) {
          throw { error: 'max scan attempts', requestData: response };
        }
      }),
      catchError(err => { throw err; })
    );
  }

  protected correctPassport(passportData: PassportScanData, passenger: InternationalPassenger): boolean {
    // Remove title from the firstName/lastName in passport and trip
    const nameInTrip = {
      'firstName': this.removeTitles(passenger.passengerName.firstName),
      'lastName': this.removeTitles(passenger.passengerName.lastName)
    };
    const nameInPassport = {
      'firstName': this.removeTitles(passportData.document.firstName),
      'lastName': this.removeTitles(passportData.document.lastName)
    };
    // TODO: This will be moved to HUB soon
    return this.paxAndPassportFuzzyMatch(nameInTrip, nameInPassport) && passportData.document.dob === passenger.dateOfBirth;
  }

  // Name check if firstName/lastName switched in the trip
  private paxAndPassportFuzzyMatch(nameInTrip, nameInPassport): boolean {
    const formattedPassportFirstName: string = nameInTrip.firstName.toUpperCase().replace(/[\W_]+/g, '');
    const formattedPassportLastName: string = nameInTrip.lastName.toUpperCase().replace(/[\W_]+/g, '');

    const formattedPassengerFirstName: string = nameInPassport.firstName.toUpperCase().replace(/[\W_]+/g, '');
    const formattedPassengerLastName: string = nameInPassport.lastName.toUpperCase().replace(/[\W_]+/g, '');

    const isLastNameValid = formattedPassportLastName === formattedPassengerLastName;
    const isFirstNameValid = formattedPassportFirstName.length > 3 
      ? formattedPassportFirstName.substring(0, 3) === formattedPassengerFirstName.substring(0, 3) 
      : formattedPassportFirstName === formattedPassengerFirstName;

    return isLastNameValid && isFirstNameValid;
  }

  // Remove titles from the name
  removeTitles(name: string) {
    let trimmedName = name.trim().toLocaleUpperCase();
    for (const title of titles) {
      const firstIndexOfTitle = trimmedName.indexOf(title);
      const lastIndexOfTitle = trimmedName.lastIndexOf(title);
      const lengthOfNameMinusTitle = trimmedName.length - title.length;

      if (firstIndexOfTitle !== -1 && lastIndexOfTitle !== -1 && lastIndexOfTitle === lengthOfNameMinusTitle) {
        // If we have both a first index and a last index, and the first index is not 0
        // and the last index is the same as the length of the name minus the length of the title
        // then we need to remove it
        trimmedName = trimmedName.substring(0, lastIndexOfTitle).trim();
      } else if (firstIndexOfTitle === 0 && lastIndexOfTitle !== lengthOfNameMinusTitle) {
        trimmedName = trimmedName.substring(title.length).trim();
      } else if (firstIndexOfTitle !== -1 && lastIndexOfTitle !== -1 && lastIndexOfTitle !== lengthOfNameMinusTitle) {
        // We have to see if the title is surrounded by spaces
        const valueNextToTitle = trimmedName[firstIndexOfTitle + title.length];
        if (!valueNextToTitle.match(/[A-Z]/)) {
          trimmedName = trimmedName.replace(title, '').trim();
        }
      }
    }
    return trimmedName;
  }

  protected getPassengerId(): string {
    let paxId: string;
    this.internationalDetailsSelectors.passportPaxId$.pipe(take(1)).subscribe(id => paxId = id);
    return paxId;
  }

  protected getPaxInfo(): InternationalPassenger {
    let tripPassenger: InternationalPassenger;
    const paxId = this.getPassengerId();
    this.internationalDetailsSelectors.internationalPassengers$.pipe(take(1)).subscribe(intlPax => 
      tripPassenger = intlPax.find(pax => pax.id === paxId));
    return tripPassenger;
  }

  protected getTripPassengersListIDs(): string[] {
    let paxIDs: string[] = [];
    this.tripPassengersList$.pipe(take(1)).subscribe(paxInTrip => paxInTrip.entries.forEach(entry => {
      paxIDs.push(entry.id);
      if (entry.hasInfant) { paxIDs.push(entry.lapInfant?.id); }
    }));

    return paxIDs;
  }
}
