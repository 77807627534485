import { Injectable } from '@angular/core';
import { BaseService } from '~app/store/services/base.service';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, timer } from 'rxjs';
import { Response } from '~app/models/response.model';
import { environment } from '~environments/environment';
import { Catalog } from '~app/models/catalog.model';
import { SessionSelectors } from '../session/session.selectors';
import { mergeMap, retryWhen} from 'rxjs/operators';

export interface ICatalogService {
  get(segmentId: string, passengerIds: string): Observable<Response<Catalog>>;
}

@Injectable({
  providedIn: 'root'
})

export class CatalogService extends BaseService implements ICatalogService {
  private authenticationString = '';
  sessionConfirmationCode$: Observable<string>;
  idBags = 'Bags';
  idSeats = 'MobileCheckInSeats';

  constructor(
    http: HttpClient,
    sessionSelectors: SessionSelectors
  ) {
    super(http, sessionSelectors);
    this.sessionConfirmationCode$ = sessionSelectors.sessionConfirmationCode$;
  }

  get(segmentId: string, passengerIds: string): Observable<Response<Catalog>> {
    this.authenticationString = this.getAuthCredentials(this.sessionConfirmationCode$);
    const param = `${this.authenticationString}&segmentId=${segmentId}&passengerIds=${passengerIds}`;
    const url = `${environment["exp-web-checkin-v2-api"]}/offers${param}`;
    return this.http.get<Response<Catalog>>(url).pipe(
      retryWhen(errors => errors.pipe(
        mergeMap((error: any, i: number) => i < 1 && error.status.toString().startsWith('5')  ? timer(1000) : throwError(error))
        ))
    );
  }
}
