import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '~environments/environment';
import { BaseService } from '~app/store/services/base.service';
import { Response } from '~app/models/response.model';
import { Trip } from '~app/models/trip.model';
import { PassengerDetail } from "~app/models/passengerdetail.model";
import { CheckInPayload } from "~app/models/checkinpayload.model";
import { PassengerList } from '~app/models/passengerlist.model';
import { ComponentRendering } from '@hawaiianair/core';
import { SessionSelectors } from '../session/session.selectors';
import * as TripActions from '../../actions/trip.actions';
import { PassengerSecurityInfo } from '~app/models/passengersecurityinfo.model';
import { SecurityInfo } from '~app/models/securityinfo.model';
import { SecurityInfoResponse } from '~app/models/security-info-model';
import { LapInfant } from '~app/models/lapinfant.model';
import { catchError, tap } from 'rxjs/operators';
import { PassengerName } from '~app/models/passengername.model';
import { TripSearch } from '~app/models/trip-search.model';
import { AdultSearch } from '~app/models/adultsearch.model';
import { Constants } from '~app/constants/ha-constants';

export interface ITripService {
  getByPnr(tripSearch: TripSearch, actionType: string, alertMessage?: object, passengerIds?: string[]):
    Observable<Response<Trip>>;
  getById(id: string): Observable<Response<Trip>>;
  checkIn(tripId: string, checkInPayload: CheckInPayload, sessionPassengerIds?: string[]): Observable<Response<Trip>>;
  setMilitaryTravelType(tripId: string, passengerUpdates: PassengerDetail[], errorRendering: ComponentRendering):
    Observable<Response<Trip>>;
  updatePassenger(tripId: string, passengerUpdates: PassengerDetail[], errorRendering: ComponentRendering):
    Observable<Response<PassengerList>>;
  addLapInfant(tripId: string, adultPassengerId: string, lapInfant: LapInfant): Observable<Response<PassengerList>>;
  removeLapInfant(tripId: string, confirmationCode: string, passengerId: string): Observable<Response<Trip>>;
  submitSecurityInfo(payload: SecurityInfo[]): Observable<Response<SecurityInfoResponse>>;
  getTripPassengersInfo(): Observable<Response<PassengerSecurityInfo>>;
  addToEliteStandByList(payload: any): Observable<Response<any>>;
}

@Injectable()
export class TripDataService extends BaseService implements ITripService {
  authenticationString = '';
  sessionConfirmationCode$: Observable<string>;
  osDevice$: Observable<string>;

  constructor(
    http: HttpClient,
    sessionSelectors: SessionSelectors,
  ) {
    super(http, sessionSelectors);
    this.sessionConfirmationCode$ = sessionSelectors.sessionConfirmationCode$;
    this.osDevice$ = sessionSelectors.osDevice$;
  }

  getByPnr(tripSearch: TripSearch, actionType: string, alertMessage?: any, passengerIds?: string[]): Observable<Response<Trip>> {
    let URL_TRIPS = `${environment["exp-web-checkin-v2-api"]}/trips`;
    URL_TRIPS += this.getSearchCredentials(tripSearch);
    URL_TRIPS += '&include=flights,passengers,status';
    // get boardingPassUrl for Apple Wallet on iOS
    let osDevice = '';
    this.osDevice$.subscribe(os => osDevice = os);
    if (actionType === TripActions.resultGetTrip.type && osDevice === Constants.ios) {
      URL_TRIPS += ',boardingpass';
    }

    if (passengerIds) {
      URL_TRIPS += `&passengerIds=${passengerIds}`;
    }
    return this.http.get<Response<Trip>>(URL_TRIPS);
  }

  getById(id: string): Observable<Response<Trip>> {
    this.authenticationString = this.getAuthCredentials(this.sessionConfirmationCode$);
    let URL_TRIP = `${environment["exp-web-checkin-v2-api"]}/trips/${id}${this.authenticationString}`;
    URL_TRIP += '&include=flights,passengers,status';
    return this.http.get<Response<Trip>>(URL_TRIP);
  }

  checkIn(tripId: string, checkInPayload: CheckInPayload, sessionPassengerIds?: string[]): Observable<Response<Trip>> {
    this.authenticationString = this.getAuthCredentials(this.sessionConfirmationCode$);
    let URL_TRIPS = `${environment["exp-web-checkin-v2-api"]}/trips/${tripId}/checkin${this.authenticationString}`;
    if (sessionPassengerIds) {
      URL_TRIPS += `&passengerIds=${sessionPassengerIds}`;
    }
    return this.http.post<any>(URL_TRIPS, checkInPayload);
  }

    setMilitaryTravelType(tripId: string,
                          passengerUpdates: PassengerDetail[],
                          ): Observable<Response<Trip>> {

      this.authenticationString = this.getAuthCredentials(this.sessionConfirmationCode$);
      const bodyPatch = passengerUpdates.map(passenger => {
        if (passenger.militaryTravelType) {
          return `{"value":"${passenger.militaryTravelType}","path":"segments/${passenger.segmentId}/details/${passenger.passengerId}/militaryTravelType","op":"replace"}`;
      }
      });
      const URL_TRIPS = `${environment["exp-web-checkin-v2-api"]}/trips/${tripId}${this.authenticationString}`;
      return this.http.patch<any>(URL_TRIPS, '[' + bodyPatch.toString() + ']');
  }

  updatePassenger(
    tripId: string,
    passengerUpdates: PassengerDetail[],
    errorRendering?: ComponentRendering
  ): Observable<Response<PassengerList>> {
    this.authenticationString = this.getAuthCredentials(this.sessionConfirmationCode$);
    const URL_TRIPS = `${environment["exp-web-checkin-v2-api"]}/trips/${tripId}/passengers${this.authenticationString}`;
    return this.http.patch<any>(URL_TRIPS, passengerUpdates);
  }

  addLapInfant(tripId: string, adultPassengerId: string, lapInfant: LapInfant): Observable<Response<PassengerList>> {
    const bodyPatch = {
      op: "add",
      path: `/passengers/${adultPassengerId}/lapInfant`,
      value: `{infantName: {firstName: '${lapInfant.infantName.firstName}', lastName: '${lapInfant.infantName.lastName}', middleName: '${lapInfant.infantName.middleName}' }}, `
        + `dateOfBirth: '${lapInfant.dateOfBirth}', gender: '${lapInfant.gender}'}`
    };
    this.authenticationString = this.getAuthCredentials(this.sessionConfirmationCode$);
    const URL_TRIPS = `${environment["exp-web-checkin-v2-api"]}/trips/${tripId}/passengers${this.authenticationString}`;
    return this.http.patch<Response<PassengerList>>(URL_TRIPS, '[' + JSON.stringify(bodyPatch) + ']').pipe(
      tap(response => {
        if (!response.results[0].entries.find(pax => adultPassengerId === pax.id).lapInfant) {
          throw { error: "lap infant not added", requestData: response };
        }
      }),
      catchError(err => { throw err; })
    );
  }

  addMinorToAdult(tripId: string, adultSearchInfo: AdultSearch, flightInfo: { flightId: string; cabin: string; }[] ) {
    const bodyPost = {
        "adult": adultSearchInfo,
        "minor": {
            "flights": flightInfo
        }
    };
    this.authenticationString = this.getAuthCredentials(this.sessionConfirmationCode$);
    const URL_TRIPS = `${environment["exp-web-checkin-v2-api"]}/trips/${tripId}/adults${this.authenticationString}`;

    return this.http.post<any>(URL_TRIPS, JSON.stringify(bodyPost)).pipe(
      catchError(err =>  {throw { error: err.error.errors[0].info };
      })
    );

  }

  removeLapInfant(tripId: string, confirmationCode: string, passengerId: string): Observable<Response<Trip>> {
    const URL_TRIPS = `${environment["exp-web-checkin-v2-api"]}/trips/${tripId}?confirmationCode=${confirmationCode}`;
    return this.http.patch<any>(URL_TRIPS, '[' + `{"path": "flightPassengers/${passengerId}/isWithInfant","op":"remove"}` + ']');
  }

  submitSecurityInfo(payload: SecurityInfo[]): Observable<Response<SecurityInfoResponse>> {
    this.authenticationString = this.getAuthCredentials(this.sessionConfirmationCode$);
    const URL_TRIPS = `${environment["exp-web-checkin-v2-api"]}/trips/securityinfo${this.authenticationString}`;
    return this.http.post<any>(URL_TRIPS, payload);
  }

  getTripPassengersInfo(): Observable<Response<PassengerSecurityInfo>> {
    this.authenticationString = this.getAuthCredentials(this.sessionConfirmationCode$);
    const URL_PASSENGER = `${environment["exp-web-checkin-v2-api"]}/trips/passengers${this.authenticationString}`;
    return this.http.get<Response<PassengerSecurityInfo>>(URL_PASSENGER);
  }

  getGroupBookingPassengerList(
    tripId: string,
    confirmationCode: string,
    passengerName: PassengerName,
    dateOfBirth?: string,
  ): Observable<Response<PassengerList>> {
    let URL_GROUP_BOOKING_PASSENGER_LIST =
      `${environment["exp-web-checkin-v2-api"]}/trips/${tripId}/passenger?confirmationCode=${confirmationCode}&lastName=${passengerName.lastName}&firstName=${passengerName.firstName}`;
    if (!!dateOfBirth) {
      URL_GROUP_BOOKING_PASSENGER_LIST += `&dateOfBirth=${dateOfBirth}`;
    }
    return this.http.get<Response<PassengerList>>(URL_GROUP_BOOKING_PASSENGER_LIST);
  }

  addToEliteStandByList(payload: any): Observable<Response<any>> {
    this.authenticationString = this.getAuthCredentials(this.sessionConfirmationCode$);
    const URL_TRIPS = `${environment["exp-web-checkin-v2-api"]}/trips/priorityListPassengers${this.authenticationString}`;
    return this.http.post<any>(URL_TRIPS, payload);
  }
}

